import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class ConfigService {
  constructor() { }

  loadbaseUrl() {
    let temp = window.location.origin + '/';
    //  const temp = 'https://northstar.attstraviso.com/';
  // const temp = 'https://northstar-dev.attstraviso.com/';
    // let temp = 'https://nsdev.straviso.net/';
  // let temp = 'https://nsfarm.straviso.net/';
   

    environment.nineDotBaseUrl = window.location.origin; //url used in JSON Dashboard component graph and custom-graph
    environment.mainurl = temp + environment.mainurl;
    environment.signInUrl = temp + environment.signInUrl;
    environment.userCountUrl = temp + environment.userCountUrl;
    environment.imgBaseUrl = environment.imgBaseUrl;
    environment.deleteUser = temp + environment.deleteUser;
    environment.northstarproductUrl = temp + environment.northstarproductUrl;
    environment.northstarcustomermanagementUrl = temp + environment.northstarcustomermanagementUrl;
    environment.botConfig = temp + environment.botConfig;
    environment.lumenwebapi = temp + environment.lumenwebapi;
    environment.chatUser = temp + environment.chatUser;
    environment.bcsCommunicationDetail = temp + environment.bcsCommunicationDetail;
    environment.confURL = temp + environment.confURL;
    environment.chatAPI = environment.chatAPI;
    environment.clinkReporting = temp + environment.clinkReporting;
    environment.lumenRporting = temp + environment.lumenRporting;
    environment.apiDetails = temp + environment.apiDetails;
    environment.botInteraction = temp + environment.botInteraction;
    environment.buzzReporting = temp + environment.buzzReporting;
    environment.fieldServeSummary = environment.fieldServeSummary;
    environment.feedbackCount = environment.feedbackCount;
    environment.fieldUsage = environment.fieldUsage;
    environment.taskEasyReporting = temp + environment.taskEasyReporting;
    environment.customerManagementRoot = temp + environment.customerManagementRoot;
    environment.lumenChatUrl = temp + environment.lumenChatUrl;
    environment.ticketDahsUrl = temp + environment.ticketDahsUrl;
    environment.calstraUrl = temp + environment.calstraUrl;
    environment.knowMeUrl = environment.knowMeUrl;
    environment.amstrckUrl = temp + environment.amstrckUrl;
    environment.workorderUrl = temp + environment.workorderUrl;
    environment.assessmentUrl = temp + environment.assessmentUrl;
    environment.partnerUrl =  temp + environment.partnerUrl;
    environment.uamBaseUrl = temp + environment.uamBaseUrl;
    environment.lumen = temp + environment.lumen;
    environment.nexus = temp + environment.nexus;
    environment.fieldServe = temp + environment.fieldServe;
    environment.buzz = temp + environment.buzz;
    environment.taskEasy = temp + environment.taskEasy;
    environment.workorderDashUrl = temp + environment.workorderDashUrl;
    environment.nexusDashUrl = temp + environment.nexusDashUrl;
    environment.knowmeDashUrl = temp + environment.knowmeDashUrl;
    environment.fieldserveDashUrl = temp + environment.fieldserveDashUrl;
    environment.amtrackDashUrl = temp + environment.amtrackDashUrl;
    environment.appointmentdashUrl = temp + environment.appointmentdashUrl;
    environment.buzzDashUrl = temp + environment.buzzDashUrl;
    environment.taskDashUrl = temp + environment.taskDashUrl;
    environment.customerMDashUrl = temp + environment.customerMDashUrl;
    environment.contactusUrl = environment.contactusUrl;
    environment.interactionUrl = temp + environment.interactionUrl;
    environment.interactionCount = temp + environment.interactionCount;
    environment.integrationgetwayUrl = temp + environment.integrationgetwayUrl;
    environment.azureBaseUrl = temp + environment.azureBaseUrl;
    environment.redirectUrl = temp + environment.redirectUrl;
    environment.azureuserloginurl = temp + environment.azureuserloginurl;
    environment.googleuserloginurl = temp + environment.googleuserloginurl;
    environment.facebookuserloginurl = temp + environment.facebookuserloginurl;
    environment.linkedinuserloginurl = temp + environment.linkedinuserloginurl;
    environment.businessDelete = temp + environment.businessDelete;
    environment.shortUrl = environment.shortUrl;
    environment.loginUser = temp + environment.loginUser;
    environment.ticketingUrl = temp + environment.ticketingUrl;
    environment.nexusMonitorUrl = temp + environment.nexusMonitorUrl;
    environment.errorBoardlumen = temp + environment.errorBoardlumen;
    environment.exceptionUrl = temp + environment.exceptionUrl;
    environment.socAPI = temp + environment.socAPI;
    environment.myworkbench = temp + environment.myworkbench;
    environment.chatBaseUrl = temp + environment.chatBaseUrl;
    environment.attUrl = temp + environment.attUrl;
    environment.myPulseUrl =  temp + environment.myPulseUrl;
    environment.loginURL = temp + environment.loginURL;
  }
}

